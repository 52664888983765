import { Platform } from 'react-native';

// Secure Token Storage
import * as SecureStore from "expo-secure-store";
import AsyncStorage from '@react-native-async-storage/async-storage';

// Secure Token Cache
export const tokenCache = {
    async getToken(key) {
        if (Platform.OS === 'web') {
            try {
                const value = await AsyncStorage.getItem(key);
                if (value !== null) {
                    return value;
                }
                return null;
            } catch (e) {
                return null;
            }
        }
        try {
            return SecureStore.getItemAsync(key);
        } catch (err) {
            return null;
        }
    },
    async saveToken(key, value) {
        if (Platform.OS === 'web') {
            try {
                await AsyncStorage.setItem(key, value);
                return;
            } catch (e) {
                return;
            }
        }
        try {
            return SecureStore.setItemAsync(key, value);
        } catch (err) {
            return;
        }
    },
    async removeToken(key) {
        if (Platform.OS === 'web') {
            try {
                await AsyncStorage.removeItem(key);
                return;
            } catch (e) {
                return;
            }
        }
        try {
            return SecureStore.deleteItemAsync(key);
        } catch (err) {
            return;
        }
    },
};