import { MotiView } from 'moti';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, useWindowDimensions } from 'react-native';

interface Props {
    speedFactor?: number;
    backgroundColor?: string;
    starColor?: string;
    starCount?: number;
}

export default function Starfield(props: Props) {
    const { speedFactor = 0.01, backgroundColor = 'black', starColor = '#FFFFFF', starCount = 1000 } = props;

    const { width, height } = useWindowDimensions();
    const [stars, setStars] = useState<Array<{ x: number; y: number; z: number, size: number, opacity: number }>>([]);

    useEffect(() => {
        const generateStars = () => {
            const newStars = [];
            for (let i = 0; i < starCount; i++) {
                const s = {
                    x: Math.random() * width,
                    y: Math.random() * height,
                    z: Math.random() * 1000,
                    size: (Math.random() * 2) + 2,
                    opacity: 1,
                };
                newStars.push(s);
            }
            setStars(newStars);
        };

        generateStars();
        const interval = setInterval(() => {
            requestAnimationFrame(moveStars);
        }, 1000 / 60); // 60 FPS

        return () => clearInterval(interval); // Clear the interval when the component unmounts
    }, [starCount, speedFactor, width, height]);

    const moveStars = () => {
        setStars((prevStars) => {
            return prevStars.map((star) => {
                let newZ = star.z - speedFactor;
                let newX = star.x;
                let newY = star.y;
                let opacity = 1 - newZ / 1000; // Calculate opacity based on z value

                // Check if the star needs to be reset because it's too close
                if (newZ <= 1) {
                    opacity = 0; // Set opacity to 0 for fade out before reset
                    setTimeout(() => { // Delay the reset to allow for fade out
                        newZ += 1000;
                        newX = Math.random() * width;
                        newY = Math.random() * height;
                    }, 1000); // Wait for 1 second (1000 milliseconds)
                }

                return {
                    ...star,
                    x: newX,
                    y: newY,
                    z: newZ,
                    opacity, // Include opacity in the star object
                };
            });
        });
    };


    return (
        <MotiView
            transition={{
                type: 'timing',
                duration: 1000,
            }}
            animate={{

            }}
            style={
                [
                    styles.container,
                    {
                        backgroundColor,
                    }
                ]
            }
        >
            {stars.map((star, index) => (
                <MotiView
                    transition={{
                        type: 'timing',
                        duration: 1000,
                    }}
                    animate={{
                        scale: 1 - star.z / 1000,
                        opacity: star.opacity,
                    }}
                    key={index}
                    style={[
                        styles.star,
                        {
                            width: star.size,
                            height: star.size,
                            left: star.x,
                            top: star.y,
                            backgroundColor: starColor,
                            filter: `blur(${star.z / 250}px)` as any,
                        },
                    ]}
                />
            ))}
        </MotiView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: 'fixed' as 'absolute',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    star: {
        position: 'absolute',
        borderRadius: 50,
    },
});