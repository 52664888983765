import React from 'react';
import { View } from 'react-native';
import Svg, { Polygon, Defs, LinearGradient, Stop } from 'react-native-svg';

const BlobBackground = () => {
    return (
        <View className='absolute inset-x-0 -top-16 -z-10 overflow- flex justify-center opacity-25 aspect-[1318/752] w-[82.375rem]'>
            <Svg
                viewBox="0 0 660 376"
                style={{
                    filter: 'blur(64px)'
                }}>
                <Defs>
                    <LinearGradient
                        id="grad"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="0%"
                    >
                        <Stop offset="0.25" stopColor="#80caff" stopOpacity="0.7" />
                        <Stop offset="0.7" stopColor="#6E00FF" stopOpacity="0.7" />
                    </LinearGradient>
                </Defs>
                <Polygon
                    points="484.608,195.192 604.452,44.496 660,175.2 643.032,309.912 609.9,319.992 499.464,240.96 364.968,178.8 306.9,186.192 297,236.664 331.584,328.992 140.652,241.728 0.066,376 35.64,192.576 141.096,240.768 388.68,0.752 484.608,195.192"
                    fill="url(#grad)"
                />
            </Svg>
        </View>
    );
};

export default BlobBackground;
