import React, { useState, useRef, useEffect } from 'react';
import { View, TextInput, Pressable, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    defaultStyle: {
        fontFamily: 'dmsans',
    },
});

const CodeInputBox = ({ value, onInputChange, onKeyPress, refInput }) => (
    <TextInput
        ref={refInput}
        value={value}
        onChangeText={onInputChange}
        onKeyPress={onKeyPress}
        keyboardType="number-pad"
        maxLength={1}
        className="w-10 h-10 border-2 border-neutral-300 dark:border-neutral-600 text-black dark:text-white mr-2 text-center text-lg font-bold rounded-full"
        returnKeyType="done"
        blurOnSubmit={false}
        style={
            [styles.defaultStyle, {
                fontSize: 21    ,
            }]
        }

    />
);

const VerifyCode = ({ setCurrentCode }) => {
    const [code, setCode] = useState(new Array(6).fill(''));
    const inputs = useRef(new Array(6).fill(null).map(() => React.createRef()));

    useEffect(() => {
        code.forEach((digit, index) => {
            if (digit !== '' && index < 5 && code[index + 1] === '') {
                inputs.current[index + 1].current.focus();
            }
        });

        if (code.join('').length === 6) {
            setCurrentCode(code.join(''));
        }
    }, [code]);

    const handleInputChange = (text, index) => {
        const newCode = [...code];
        newCode[index] = text;
        setCode(newCode);
    };

    const handleKeyPress = (e, index) => {
        if (e.nativeEvent.key === 'Backspace' && index > 0) {
            const newCode = [...code];
            newCode[index] = '';
            newCode[index - 1] = '';
            setCode(newCode);
            inputs.current[index - 1].current.focus();
        }
    };

    return (
        <View className="flex-1 justify-center items-center">
            <View className="flex-row mb-5">
                {code.map((digit, index) => (
                    <CodeInputBox
                        key={index}
                        refInput={inputs.current[index]}
                        value={digit}
                        onInputChange={(text) => handleInputChange(text, index)}
                        onKeyPress={(e) => handleKeyPress(e, index)}
                    />
                ))}
            </View>
        </View>
    );
};

export default VerifyCode;