import React, { useEffect, useRef, useState } from "react";
import { SafeAreaView, Text, StyleSheet, View, Button, Pressable } from "react-native";

// Linking
import * as Linking from 'expo-linking';
import useHashChange from "./hooks/useHashChange";

// Display / Navigation
import Display from "./components/navigation/Display";

// Page Components
import SearchComponent from "./components/app/Search";
import Statements from "./components/app/Statements";
import Account from "./components/app/Account";
import NotFound from "./components/app/NotFound";

// Authentication
import { AuthProvider, IsSignedIn, IsSignedOut, useAuth } from "./auth/AuthProvider";
import { AuthScreen, useAuthScreen } from "./auth/AuthScreens";

// Animations
import 'react-native-reanimated'
import 'react-native-gesture-handler'
import { AnimatePresence, MotiView } from 'moti';

import {
  MoonSat,
  SunLight
} from 'iconoir-react-native';

import { tokenCache } from "./auth/TokenCache";

const MoonSatIcon = styled(MoonSat);
const SunLightIcon = styled(SunLight);

// Animation Fix
import { Platform } from 'react-native'
if (Platform.OS === 'web') {
  global._frameTimestamp = null
}

// Styles
import { NativeWindStyleSheet, styled, useColorScheme } from 'nativewind';

// TailwindCSS Bug Fix:
NativeWindStyleSheet.setOutput({
  default: "native",
});

// Fonts
import useFonts from './hooks/useFonts';

// Splash Screen
import SplashScreen from "./components/SplashScreen";

// Extensions
import AfinityText from "./extensions/AfinityText";
import Starfield from "./hooks/useStarfield";
import BlobBackground from "./components/Blob";

// Icons
import {
  HomeSimple,
  Search,
  Compass,
  UserCircle,
  DocStar
} from 'iconoir-react-native';

const HomeSimpleIcon = styled(HomeSimple);
const SearchIcon = styled(Search);
const CompassIcon = styled(Compass);
const UserCircleIcon = styled(UserCircle);
const DocStarIcon = styled(DocStar);

const tabs = [
  {
    index: 1,
    name: 'Personal Statements',
    uid: 'statements',
    component: Statements,
    icon: DocStarIcon,
    isMobile: false,
  },
  {
    index: 2,
    name: 'Search',
    uid: 'search',
    component: SearchComponent,
    icon: SearchIcon,
    isMobile: true,
  },
  {
    index: 3,
    name: 'Account',
    uid: 'account',
    component: Account,
    icon: UserCircleIcon,
    isMobile: true,
  }
]

export default function App() {
  const [currentPage, setCurrentPage] = React.useState('');
  const [fontLoaded, setFontLoaded] = React.useState(false);
  const [requestedUser, setRequestedUser] = React.useState(null);

  const url = Linking.useURL();
  const currentHash = useHashChange();
  const wasProgrammaticChange = React.useRef(false);

  // Use color scheme hook to toggle between light and dark mode.
  const { colorScheme, setColorScheme } = useColorScheme();

  // Effect for loading the initial color scheme
  React.useEffect(() => {
    async function loadAndSetColourScheme() {
      let savedColourScheme = await tokenCache.getToken('colorScheme');

      // If there is no saved color scheme, default to 'light'
      if (!savedColourScheme) {
        savedColourScheme = 'light';
        await tokenCache.saveToken('colorScheme', savedColourScheme);
      }

      // Set the loaded color scheme without toggling
      setColorScheme(savedColourScheme);
    }

    loadAndSetColourScheme();
  }, [setColorScheme]);

  // Effect for saving the color scheme whenever it changes
  React.useEffect(() => {
    async function saveColourScheme() {
      await tokenCache.saveToken('colorScheme', colorScheme);
    }

    // Avoid saving the initial state (right after loading)
    if (colorScheme !== undefined) {
      saveColourScheme();
    }
  }, [colorScheme]);

  React.useEffect(() => {
    async function loadFonts() {
      await useFonts();
      setFontLoaded(true);
    }
    loadFonts();
  }, []);

  React.useEffect(() => {
    if (wasProgrammaticChange.current) {
      wasProgrammaticChange.current = false;
      return;
    }

    if (url) {
      const matchUsername = url.match(/\/@([^\/]+)$/);
      const matchPath = url.match(/\/([^\/]+)$/);

      if (matchUsername) {
        const username = matchUsername[1];
        setRequestedUser(username);
        setCurrentPage('profile');
      } else if (matchPath) {
        const path = matchPath[1];
        // split the path by /
        const pathParts = path.split('/');
        // if the first path part is in the tabs, set the current page to that, otherwise set it to search
        if (tabs.find((tab) => tab.uid === pathParts[0])) {
          setCurrentPage(pathParts[0]);
        } else {
          setCurrentPage('search');
        }
      } else {
        setCurrentPage('search');
      }
    }

    if (currentHash) {
      const matchPath = currentHash.match(/^#\/(.+)$/);
      if (matchPath && matchPath[1] && matchPath[1] !== currentPage && matchPath[1] !== 'profile') {
        const path = matchPath[1];
        setCurrentPage(path);
      }
    }

    // Fail-safe, if the current page is empty, set it to search
    if (currentPage === '' && Platform.OS !== 'web') {
      setCurrentPage('search');
    }
  }, [url, currentHash]);

  React.useEffect(() => {
    if (currentPage !== '' && currentPage !== 'profile') {
      wasProgrammaticChange.current = true;
      Linking.openURL(`/#/${currentPage}`).catch(err => (err));
    }
  }, [currentPage]);

  if (!fontLoaded) {
    return <SplashScreen />;
  }

  return (
    <AuthProvider>
      <ContentComponent setCurrentPage={setCurrentPage} currentPage={currentPage} requestedUser={requestedUser} setRequestedUser={setRequestedUser} />
    </AuthProvider>
  );
}

function ContentComponent({ setCurrentPage, currentPage, requestedUser = null, setRequestedUser = () => { } }) {
  const [currentIndex, setCurrentIndex] = useState(tabs.find((tab) => tab.uid === currentPage)?.index);
  const [transitionDirection, setTransitionDirection] = useState('forward'); // New state to track transition direction
  const prevIndexRef = useRef(currentIndex);

  // Update transition direction when currentPage changes
  useEffect(() => {
    const currentTab = tabs.find(tab => tab.uid === currentPage);
    if (currentTab) {
      const newIndex = currentTab.index;
      const oldIndex = prevIndexRef.current;
      setTransitionDirection(newIndex > oldIndex ? 'forward' : 'backward');
      setCurrentIndex(newIndex); // Update the current index after determining the direction
      prevIndexRef.current = newIndex; // Update the previous index reference immediately
    }
  }, [currentPage]);

  const currentView = () => {
    const Component = tabs.find((tab) => tab.uid === currentPage)?.component;
    return Component ? <Component user={requestedUser} setRequestedUser={setRequestedUser} /> : <NotFound />;
  };

  // Animation for entering and exiting the view based on the transition direction
  const animatePageTransition = {
    from: {
      opacity: 0,
      translateX: transitionDirection === 'forward' ? 300 : -300,
    },
    animate: {
      opacity: 1,
      translateX: 0,
    },
    exit: {
      opacity: 0,
      translateX: transitionDirection === 'forward' ? -300 : 300,
    },
  };

  const { colorScheme, toggleColorScheme } = useColorScheme();

  useEffect(() => {
    // if requestedUser changes, but is not null, set the current page to profile
    if (requestedUser) {
      setCurrentPage('profile');
    }
  }, [requestedUser]);

  useEffect(() => {
    // if the currentPage changes, but is not profile, set the requestedUser to null
    if (currentPage !== 'profile') {
      setRequestedUser(null);
    }
  }, [currentPage]);

  return (
    <>
      <IsSignedIn>
        <View className="flex-1">
          <View className="flex-1 overflow-hidden">
            <Display type={Platform.OS} tabs={tabs} setCurrentPage={setCurrentPage} currentPage={currentPage} setRequestedUser={setRequestedUser}>
              <Starfield
                starColor="#fafafa"
                backgroundColor="#09090b"
                starCount={200}
                speedFactor={0.25}
              />
              <AnimatePresence exitBeforeEnter>
                <MotiView
                  key={currentPage}
                  {...animatePageTransition}
                  transition={{ type: 'timing', duration: 1000 }}
                  style={{ flex: 1 }}
                >
                  {currentView()}
                </MotiView>
              </AnimatePresence>
            </Display>
          </View>
        </View>
      </IsSignedIn>
      <IsSignedOut>
        <View className="flex-1 flex justify-center items-center">
          <AuthScreen />
        </View>
      </IsSignedOut>
    </>
  );
}