import React, { createContext, useContext, useState } from "react";
import { Text, TextInput, Pressable, View } from "react-native";
import { useSignIn, useSignUp } from "./AuthExtras";

// Styles
import { NativeWindStyleSheet, useColorScheme } from 'nativewind';
import AfinityText from "../extensions/AfinityText";
import VerifyCode from "./VerifyCode";

NativeWindStyleSheet.setOutput({
    default: "native",
});

function SignInScreen() {
    const { signIn, setActive, isLoaded } = useSignIn();

    const [currentStep, setCurrentStep] = React.useState(1); // 1 = Identifier, 2 = Verification
    const [identifier, setIdentifier] = React.useState("");
    const [code, setCode] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);

    const handleCompletedCode = (code) => {
        setCode(code);
    };

    const onPrepareVerification = async () => {
        if (!identifier) {
            // TODO: Show an error message to the user that identifier is required
            return;
        }
        try {
            setIsLoading(true);
            await signIn.prepareLogin(
                {
                    identifier: identifier,
                    strategy: "identifier_code"
                }
            );
            setCurrentStep(2);
            setIsLoading(false);
        } catch (err) {
            console.error(JSON.stringify(err, null, 2));
            setIsLoading(false);
        }
    };

    const onSignInPress = async () => {
        if (!isLoaded) {
            return;
        }

        try {
            setIsLoading(true);
            const completeSignIn = await signIn.attemptAuthentication(
                {
                    identifier: identifier,
                    code: code,
                }
            );
            //console.warn(JSON.stringify(completeSignIn, null, 2));
            await setActive({ session: completeSignIn.response.afinity_token });
            //console.log("Signed in with token: " + completeSignIn.response.afinity_token);
            setIsLoading(false);
        } catch (err) {
            console.error(err);
            setIsLoading(false);
            // TODO: Handle error by providing a message to user
        }
    };

    return (
        <View className="bg-white dark:bg-neutral-900 border border-stone-200 dark:border-neutral-700 rounded-2xl max-w-md w-full mx-auto h-80 p-4">
            <AfinityText className="text-3xl font-bold text-center text-black dark:text-white mb-4">
                Log In
            </AfinityText>
            {currentStep === 1 && (
                <>
                    <AfinityText className="text-md font-normal text-center text-black dark:text-white mb-4">
                        Enter your username
                    </AfinityText>
                    <View className="flex-1">
                        <TextInput
                            autoCapitalize="none"
                            value={identifier}
                            placeholder="Enter your username"
                            className="bg-white text-black dark:bg-neutral-950 dark:text-white p-4 border-2 border-stone-300 dark:border-neutral-700 rounded-full"
                            onChangeText={(identifier) => setIdentifier(identifier)}
                        />
                        <Pressable onPress={onPrepareVerification} className="bg-[#FF008F] p-4 rounded-full mt-auto" disabled={isLoading}>
                            <AfinityText className="text-white text-center">
                                {
                                    isLoading ? "Loading..." : "Next"
                                }
                            </AfinityText>
                        </Pressable>
                    </View>
                </>
            )}

            {currentStep === 2 && (
                <>
                    <View className="flex-1">
                        <AfinityText className="text-md font-normal text-center text-black dark:text-white mb-4">
                            Enter the verification code sent to {identifier}
                        </AfinityText>
                        <VerifyCode setCurrentCode={handleCompletedCode} />
                        <Pressable onPress={onSignInPress} className="bg-[#FF008F] p-4 rounded-full mt-auto">
                            <Text className="text-white text-center">Sign in</Text>
                        </Pressable>
                    </View>
                </>
            )}
        </View>
    );
}

function SignUpScreen() {
    const { isLoaded, signUp, setActive, checkUsername } = useSignUp();

    const [currentStep, setCurrentStep] = React.useState(1); // 1 = Identifier, 2 = Verification, 3 = Account Setup
    const [username, setUsername] = React.useState("");
    const [identifier, setIdentifier] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [code, setCode] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState(null);

    const colorScheme = useColorScheme();

    const [afinityToken, setAfinityToken] = React.useState(null);

    const handleCompletedCode = (code) => {
        setCode(code);
    };

    const onUsernameCheck = async () => {
        if (!username) {
            setErrorMessage("Username is required.");
            return;
        }
        const isAvailable = await checkUsername(
            username,
            afinityToken
        );
        if (isAvailable) {
            setErrorMessage(null);
        } else {
            setErrorMessage("Username is already taken.");
        }
    };

    const onProceedToVerification = async () => {
        try {
            await signUp.prepareVerification({ strategy: "identifier_code", identifier: identifier });
            setCurrentStep(2);
        } catch (err) {
            console.error(JSON.stringify(err, null, 2));
        }
    };

    // This creates an account with a temporary username.
    const onProceedToAccountSetup = async () => {
        try {
            const completeSignUp = await signUp.attemptVerification(
                {
                    identifier: identifier,
                    code: code
                }
            );
            setAfinityToken(completeSignUp.afinity_token);
            setCurrentStep(3);
        } catch (err) {
            console.error(JSON.stringify(err, null, 2));
        }
    };

    // This finishes the account setup by changing the username from temp_... to the desired username.
    const onFinishSignUp = async () => {
        if (!isLoaded) {
            return;
        }
        try {
            await signUp.create(
                {
                    afinity_token: afinityToken,
                    username: username
                }
            );
            await setActive(
                {
                    session: afinityToken
                }
            );
        } catch (err) {
            console.error(JSON.stringify(err, null, 2));
        }
    };

    return (
        <View className="bg-white dark:bg-neutral-900 border border-stone-200 dark:border-neutral-700 rounded-2xl max-w-md w-full mx-auto h-80 p-4">
            <AfinityText className="text-3xl font-bold text-center text-black dark:text-white mb-4">
                Sign Up
            </AfinityText>
            {currentStep === 1 && (
                <View className="flex-1">
                    <AfinityText className="text-md font-normal text-center text-black dark:text-white mb-4">
                        Enter your email address or phone number
                    </AfinityText>
                    <TextInput
                        value={identifier}
                        placeholder="Email or Phone..."
                        className="bg-white text-black dark:bg-neutral-950 dark:text-white p-4 border-2 border-stone-300 dark:border-neutral-700 rounded-full"
                        onChangeText={(id) => setIdentifier(id)}
                    />
                    <Pressable onPress={onProceedToVerification} className="bg-[#FF008F] p-4 rounded-full mt-auto">
                        <Text className="text-white text-center">Next</Text>
                    </Pressable>
                </View>
            )}

            {currentStep === 2 && (
                <View className="flex-1">
                    <AfinityText className="text-md font-normal text-center text-black dark:text-white mb-4">
                        Enter the verification code sent to {identifier}
                    </AfinityText>
                    <VerifyCode setCurrentCode={handleCompletedCode} />
                    <Pressable onPress={onProceedToAccountSetup} className="bg-[#FF008F] p-4 rounded-full mt-auto">
                        <Text className="text-white text-center">Verify</Text>
                    </Pressable>
                </View>
            )}

            {currentStep === 3 && (
                <View className="flex-1">
                    {
                        errorMessage && (
                            <Text className="text-red-500">
                                {
                                    errorMessage
                                }
                            </Text>
                        )}

                    <TextInput
                        value={username}
                        placeholder="Enter your new username..."
                        className="bg-white text-black dark:bg-neutral-950 dark:text-white p-4 border-2 border-stone-300 dark:border-neutral-700 rounded-full"
                        onBlur={onUsernameCheck}
                        onChangeText={(username) => setUsername(username)}
                    />

                    <Pressable onPress={onFinishSignUp} className="bg-[#FF008F] p-4 rounded-full mt-auto">
                        <Text className="text-white text-center">Sign up</Text>
                    </Pressable>
                </View>
            )}
        </View>
    );
}

const AuthScreenContext = createContext();

function AuthScreen() {
    const [screen, setScreen] = useState('signin'); // 'signin' or 'signup'

    const toggleScreen = () => {
        setScreen((prevScreen) => (prevScreen === 'signin' ? 'signup' : 'signin'));
    };

    return (
        <AuthScreenContext.Provider value={{ toggleScreen }}>
            <View className="bg-white dark:bg-neutral-950 border border-stone-200 dark:border-neutral-800 rounded-[32px] p-4 max-w-md w-full mx-auto">
                {screen === 'signin' && <SignInScreen />}
                {screen === 'signup' && <SignUpScreen />}
                <View className="bg-white dark:bg-neutral-900 border border-stone-200 dark:border-neutral-700 rounded-2xl p-4 max-w-md w-full mx-auto mt-4">
                    <Pressable onPress={toggleScreen} className="">
                        <Text className="text-black dark:text-neutral-200 text-center">
                            {screen === 'signin' ? 'Sign Up Instead' : 'Sign In Instead'}
                        </Text>
                    </Pressable>
                </View>
            </View>
        </AuthScreenContext.Provider>
    );
}

function useAuthScreen() {
    const context = useContext(AuthScreenContext);
    if (!context) {
        throw new Error('useAuthScreen must be used within an AuthScreenProvider');
    }
    return context;
}

export { AuthScreen, useAuthScreen };