import React, { useEffect } from 'react';
import { useWindowDimensions, View, ScrollView, Pressable, Text, SafeAreaView, Platform } from "react-native";
import * as Linking from 'expo-linking';

import AfinityText from '../../extensions/AfinityText';

import { useColorScheme } from "nativewind";
import {
  MoonSat,
  SunLight,
  LogOut
} from 'iconoir-react-native';

import { MotiView } from 'moti';

import { useAuth } from '../../auth/AuthProvider';
import { tokenCache } from "../../auth/TokenCache";

export default function Display({ children, tabs, currentPage, setCurrentPage, setRequestedUser = () => { } }) {
  const [username, setUsername] = React.useState('');

  const { width } = useWindowDimensions();

  const { signOut } = useAuth();

  // Adjust the breakpoint as needed.
  const isMobile = width < 600;
  const isTablet = width >= 600 && width < 1264;
  const isDesktop = width >= 1264;

  // Use color scheme hook to toggle between light and dark mode.
  const { colorScheme, toggleColorScheme } = useColorScheme();

  const mobileFix = (Platform.OS === 'ios' && isMobile) ? ' h-20' : 'h-16';

  useEffect(() => {
    async function getUserDetails() {
      setUsername(await tokenCache.getToken('username'));
    }

    getUserDetails();
  }, []);

  return (
    <MotiView
      transition={{
        type: 'timing',
      }}
      className={`flex-1 `}
    >
      <View className={`flex-1 overflow-hidden`}>
        {/* Main content */}
        <View className={`flex-1`}>
          <MotiView
            transition={{
              type: 'timing',
            }}
            className={`z-0 flex-1`}
          >
            {children}
          </MotiView>
        </View>

        {/* Sidebar/Navigation */}
        <MotiView
          className={
            `flex z-10 top-0 left-0 justify-center items-center w-full bg-transparent border-0`
          }
          style={{
            position: 'fixed',
          }}
        >
          <View className={`flex-row justify-between w-full items-center py-4 px-4`}>
            {tabs.map((tab) => (
              <Pressable
                key={tab.uid}
                onPress={() => {
                  if (tab.uid === 'profile') {
                    if (Platform.OS === 'web') {
                      Linking.openURL(`/@${username}`).catch(err => (err));
                    } else {
                      setCurrentPage(tab.uid);
                      setRequestedUser(username);
                    }
                  } else {
                    if (currentPage === "profile") {
                      if (Platform.OS === 'web') {
                        Linking.openURL(`/#/${tab.uid}`).catch(err => (err));
                      } else {
                        setCurrentPage(tab.uid);
                      }
                    } else {
                      setCurrentPage(tab.uid);
                    }
                  }
                }}
                className={`flex w-12 justify-center items-center h-12 rounded-full overflow-hidden bg-transparent`} // auto needs to change here
              >
                <MotiView
                  className={`w-full h-12 flex-row p-2 items-center bg-zinc-50/10 border-2 rounded-full`}
                  animate={{
                    backgroundColor: tab.uid === currentPage ? (
                      'rgba(250, 250, 250, 0.10)'
                    ) : (
                      'rgba(250, 250, 250, 0.05)'
                    ),
                    borderColor: tab.uid === currentPage ? (
                      'rgba(250, 250, 250, 0.10)'
                    ) : (
                      'rgba(250, 250, 250, 0.05)'
                    ),
                  }}
                >
                  <MotiView
                    animate={{
                      color: '#fff',
                    }}
                    transition={{
                      type: 'timing',
                    }}
                    className={`flex justify-center items-center w-full`}
                  >
                    <tab.icon width={24} height={24} color={'#fff'} />
                  </MotiView>
                </MotiView>
              </Pressable>
            ))}
          </View>
        </MotiView>
      </View>
    </MotiView>
  );
}
