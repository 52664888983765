import React from 'react';
import { Text, TextInput, TouchableOpacity, View, Button, SafeAreaView } from "react-native";
import { SparklesIcon } from "react-native-heroicons/solid";

import { useColorScheme } from 'nativewind';

import AfinityText from '../../extensions/AfinityText';

export default function Explore({ }) {

  // Use color scheme hook to toggle between light and dark mode.
  const { colorScheme, toggleColorScheme } = useColorScheme();

  return (
    <SafeAreaView className="flex-1">
      <View className="flex-1 flex justify-center items-center">
        <AfinityText className="text-2xl font-bold text-white">
          Your Personal Statement Area
        </AfinityText>
      </View>
    </SafeAreaView>
  );
}