import React, { useState } from "react";
import useApi from "../api/useApi";
import { useAuth } from "./AuthProvider";
import { tokenCache } from "./TokenCache";

export const useSignIn = () => {
    const [isLoaded, setIsLoaded] = useState(true);
    const { post } = useApi();
    const { setIsSignedIn } = useAuth();

    const signIn = {
        prepareLogin: async ({ identifier, strategy }) => {
            const endpoint = "/v1/users/login/prepare";
            const body = {
                identifier: identifier,
            };

            const response = await post(endpoint, body);

            if (!response || response.error) {
                let error = response?.error || "Unknown error";
                throw new Error(error);
            }

            //await setActive({ session: response.sessionId });
            return { response };
        },
        attemptAuthentication: async ({
            identifier,
            code,
        }) => {
            if (!code) throw new Error("No password or code provided");
            if (!identifier) throw new Error("No username was provided");

            const endpoint = "/v1/users/login/attempt";
            const body = {
                identifier: identifier,
                code: code,
            };

            const response = await post(endpoint, body);

            if (!response || response.error) {
                throw new Error(response?.error || "Unknown error");
            }

            //await setActive({ session: response.sessionId });
            return { response };
        },
    };

    const setActive = async ({ session }) => {
        if (session) {
            await tokenCache.saveToken('afinityToken', session);
            setIsSignedIn(true);
        }
    };

    return { signIn, setActive, isLoaded };
};

export const useSignUp = () => {
    const [isLoaded, setIsLoaded] = useState(true);
    const { post } = useApi();
    const { setIsSignedIn } = useAuth();

    const signUp = {
        create: async ({ username, afinity_token }) => {
            const endpoint = "/v1/users/username/change"
            const body = {
                username: username,
                afinity_token: afinity_token,
            };

            const response = await post(endpoint, body);

            if (!response || response.error) {
                throw new Error(response?.error || "Unknown error");
            }
        },
        prepareVerification: async ({ strategy, identifier }) => {
            const endpoint = "/v1/users/signup/prepare";
            const body = {
                identifier: identifier,
            };

            const response = await post(endpoint, body);

            if (!response || response.error) {
                throw new Error(response?.error || "Unknown error");
            }

            return { temporaryUserId: response.temporaryUserId };
        },
        attemptVerification: async (
            {
                identifier,
                code
            }
        ) => {
            const endpoint = "/v1/users/signup/attempt";
            const body = {
                identifier: identifier,
                code: code,
            };

            const response = await post(endpoint, body);

            if (!response || response.error) {
                throw new Error(response?.error || "Unknown error");
            }

            //await setActive({ session: response.afinity_token });
            return { afinity_token: response.afinity_token };
        },
    };

    const setActive = async ({ session }) => {
        if (session) {
            await tokenCache.saveToken('afinityToken', session);
            setIsSignedIn(true);
        }
    };

    const checkUsername = async (username, afinity_token) => {
        const endpoint = "/v1/users/username";
        const body = {
            username: username,
            afinity_token: afinity_token,
        };

        const response = await post(endpoint, body);

        if (!response || response.error) {
            throw new Error(response?.error || "Unknown error");
        }

        return response.is_available;
    }

    return { signUp, setActive, isLoaded, checkUsername };
};
