import React, { useState } from 'react';
import { Platform } from 'react-native';

const useHashChange = () => {
    const [hash, setHash] = React.useState(window?.location?.hash);

    if (Platform.OS !== 'web') {
        return null;
    }

    React.useEffect(() => {
        const handleHashChange = () => {
            setHash(window.location.hash);
        };

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return hash;
};

export default useHashChange;