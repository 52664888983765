import React from 'react';
import { Text, TextInput, TouchableOpacity, View, Button, SafeAreaView } from "react-native";

import { useColorScheme } from 'nativewind';

export default function SplashScreen({ }) {

    // Use color scheme hook to toggle between light and dark mode.
    const { colorScheme, toggleColorScheme } = useColorScheme();

    return (
        <View className="flex-1 bg-neutral-950">
            <View className="">
            </View>
        </View>
    );
}
